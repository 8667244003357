export const MenuItemsLocker = [
    {
      title: "Lock",
      url: "/locker",
      cName: "nav-links",
    },
    {
      title: "VISTA",
      url: "/lockvista",
      cName: "nav-links",
    },
    {
      title: " ",
      url: "/rewardsLocker",
      cName: "nav-links",
    },
  ];
  
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { MenuItemsStake } from "./MenuItemsStake";
import "../NavBar/NavBar.css";
import rewardsgif from "../assets/images/rewards.gif";

const NavItem = ({ item, isSelected, onClick }) => (
  <li className="mainemenubutton" role="tab" aria-selected={isSelected}>
    <Link className={item.cName} to={item.url} onClick={onClick}>
      {item.title === " " ? (
        <img src={rewardsgif} alt="Open Rewards Tab" className="rewardsgif" />
      ) : (
        item.title
      )}
    </Link>
  </li>
);

const NavBarHardstake = () => {
  const [selectedTab, setSelectedTab] = useState(0);

  useEffect(() => {
    console.log("NavBarHardstake mounted, setting selectedTab to 0");
    setSelectedTab(0);
  }, []);

  return (
    <div className="window-body">
      <menu className="mainmenu" role="tablist">
        {MenuItemsStake.map((item, index) => (
          <NavItem
            key={index}
            item={item}
            isSelected={selectedTab === index}
            onClick={() => {
              console.log(`Tab ${index} clicked`);
              setSelectedTab(index);
            }}
          />
        ))}
      </menu>
      <div className="tab-content">
        {MenuItemsStake[selectedTab] && MenuItemsStake[selectedTab].content}
      </div>
    </div>
  );
};

export default NavBarHardstake;

import React, { useState, useEffect } from "react";
import "../assets/styles.css";
import goldcoin from "../assets/images/goldcoin.png";
import lightningIcon from "../assets/images/lightning.png";
import openPadlock from "../assets/images/open-locker.png";
import {
  lpvistalock,
  lpvistaunlock,
  lpvistastaker,
} from "../ethereumFunctions";

function LockVista(props) {
  const [stakedAmount, setStakedAmount] = useState("");
  const [amount, setAmount] = useState("");
  const [lockDuration, setLockDuration] = useState("14 days");
  const [timeLeft, setTimeLeft] = useState("");

  useEffect(() => {
    const fetchStakerInfo = async () => {
      try {
        const { amount, timeLeftFormatted, currentShare } = await lpvistastaker(
          props.network.signer
        );
        setStakedAmount(amount.toString());
        setTimeLeft(timeLeftFormatted.toString());
      } catch (error) {
        console.error("Error fetching staker info:", error);
      }
    };

    if (props.network.signer) {
      fetchStakerInfo();
    }
  }, [props.network.signer]);

  const handleStake = async () => {
    await lpvistalock(amount, props.network.router, props.network.signer);
  };

  const handleUnstake = async () => {
    await lpvistaunlock(amount, props.network.signer);
  };

  return (
    <div className="fitcontent paddingBot">
      <div className="window scrollcontainer lg-padding" role="tabpanel">
        <fieldset>
          <legend>Lock your $VISTA liquidity and earn</legend>
          <p className="subtitles correctFont">
            Staked amount:
            <span className="stakeInfo">{stakedAmount}</span>
          </p>

          <p className="subtitles correctFont">
            Lock time:
            <span className="stakeInfo">{lockDuration}</span>
          </p>

          <p className="subtitles correctFont">
            Time left:
            <span className="stakeInfo">{timeLeft}</span>
          </p>

          <p className="subtitles correctFont">Amount:</p>
          <div className="BalanceBoxlg">
            <input
              placeholder="Enter amount"
              className="BalanceBoxBody"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
            />
            <img src={goldcoin} alt="Gold Coin" className="goldCoin"></img>
          </div>
        </fieldset>
      </div>
      <p
        style={{
          fontSize: "8px",
          textAlign: "center",
          opacity: 0.75,
          marginTop: 15,
          lineHeight: 1.3,
        }}
      >
        Do not forget to claim rewards before <br />
        locking/unlocking or else they will be lost.
      </p>
      <button onClick={handleStake} className="swapbutton">
        <h6 className="BalanceBoxBody">Hardlock</h6>
        <img src={lightningIcon} alt="Lock" className="lockicon" />
      </button>
      <button onClick={handleUnstake} className="swapbutton">
        <h6 className="BalanceBoxBody">Unlock</h6>
        <img src={openPadlock} alt="Lock" className="lockicon" />
      </button>
    </div>
  );
}

export default LockVista;

import React, { useState } from "react";
import "../assets/styles.css";
import goldcoin from "../assets/images/goldcoin.png";
import {
  lock,
  claimRewardsLOCK,
  getStatsLOCK,
  getStakerInfoLOCK,
} from "../ethereumFunctions";

function CoinLocker(props) {
  const [pairAddress, setPairAddress] = useState("");
  const [lockedAmount, setLockedAmount] = useState("");

  const handleLock = async () => {
    await lock(
      pairAddress,
      lockedAmount,
      props.network.router,
      props.network.signer
    );
  };

  return (
    <div className="fitcontent paddingBot">
      <div className="window scrollcontainer lg-padding" role="tabpanel">
        <fieldset>
          <legend>Lock</legend>

          <p className="subtitles correctFont">Pair Address:</p>
          <div className="BalanceBoxlg pairAddressInput">
            <input
              placeholder="Enter pair address"
              className="BalanceBoxBody"
              value={pairAddress}
              onChange={(e) => setPairAddress(e.target.value)}
            />
          </div>

          <p className="subtitles correctFont">Lock Amount:</p>
          <div className="BalanceBoxlg">
            <input
              placeholder="Enter amount to lock"
              className="BalanceBoxBody"
              value={lockedAmount}
              onChange={(e) => setLockedAmount(e.target.value)}
            />
            <img src={goldcoin} alt="Gold Coin" className="goldCoin"></img>
          </div>
        </fieldset>
      </div>
      <p
        style={{
          fontSize: "8px",
          textAlign: "center",
          opacity: 0.75,
          marginTop: 15,
          lineHeight: 1.3,
        }}
      >
        Do not forget to claim rewards before <br />
        locking/unlocking or else they will be lost.
      </p>

      <button onClick={handleLock} className="swapbutton">
        <h6 className="BalanceBoxBody">Lock</h6>
        <img
          src="https://win98icons.alexmeub.com/icons/png/mouse_padlock.png"
          alt="Lock"
          className="lockicon"
        />
      </button>
    </div>
  );
}

export default CoinLocker;

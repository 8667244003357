export const MenuItemsStake = [
  {
    title: "Stake",
    url: "/stake",
    cName: "nav-links",
  },
  {
    title: " ",
    url: "/RewardsStake",
    cName: "nav-links",
  },
  {
    title: "Boost",
    url: "/stakeBoost",
    cName: "nav-links",
  },
];
